import { Provider } from "features/model/provider";
import DriverEaring from "features/reports/pages/drivers-earing";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import SpinnerForPages from "shared/component/spinner-for-pages";

import Credit from "./credit";
import PublicProviderVehicleDetail from "./public-provider-vehicle-detail";
import PublicProviderTripHistory from "./publick-provider-trip-history";
import styles from "./style.module.css";
import { ResponsiveContainer } from "recharts";
import { useSendTemporaryPasswordForDriversMutation } from "../api/public-provider-services";

interface PublicProviderDetailProps {
  selectedProvider: any;
  selectedProviderLoading: boolean;
}
function PublicProviderDetail(props: PublicProviderDetailProps) {
  const [selectedProvider, setSelectedProvider] = useState<Provider>(
    props.selectedProvider
  );
  const [skip, setSkip] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [sendTemporaryPassword] = useSendTemporaryPasswordForDriversMutation();

  useEffect(() => {
    setSelectedProvider(props.selectedProvider);
    if (props.selectedProvider) {
      setSkip(false);
    }
  }, [props.selectedProvider]);

  const onCollapsed = () => {
    var isExpand = !isCollapsed;
    setIsCollapsed(isExpand);
  };

  return (
    <div className="d-flex flex-column" style={{ gap: "20px" }}>
      {props.selectedProviderLoading ? (
        <SpinnerForPages />
      ) : (
        <>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div> Provider Detail</div>
              <div className="d-flex">
                {""}
                <Button size="sm" onClick={onCollapsed}>
                  {isCollapsed ? "Expand" : "Collapse"}
                </Button>
                <Button
                  onClick={() =>
                    sendTemporaryPassword({ id: selectedProvider.id })
                  }
                  style={{ marginLeft: "20px" }}
                >
                  Send Temporary Password
                </Button>
              </div>
            </Card.Header>
            <Card.Body className={isCollapsed ? "d-none" : ""}>
              <div className="border mt-2 mx-2 pt-2">
                {/* Name */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Name</div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.first_name} {selectedProvider?.last_name}
                  </div>
                </div>

                {/* Gender */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Gender</div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.gender}
                  </div>
                </div>
                {/* Email */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Email</div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.email}
                  </div>
                </div>
                {/* Phone */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Phone Number
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.phone_number}
                  </div>
                </div>
                {/* Emergency */}
                {selectedProvider?.emergency_contact && (
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-25 ${styles.listKey} `}>
                      {" "}
                      Emergency Contact
                    </div>
                    <div className={`p-2 w-75 ${styles.list} `}>
                      {selectedProvider?.emergency_contact}
                    </div>
                  </div>
                )}

                {/* status */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Status </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.is_active ? "Active" : "Inactive"}
                  </div>
                </div>
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>City </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.address?.city}
                  </div>
                </div>
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>SubCity </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.address?.sub_city}
                  </div>
                </div>
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Woreda </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.address?.woreda}
                  </div>
                </div>
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    House Number{" "}
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedProvider?.address?.house_number}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <PublicProviderVehicleDetail
            selectedVehicle={props?.selectedProvider?.vehicle}
            selectedVehicleLoading={props?.selectedProviderLoading}
          />

          <Credit />
          <DriverEaring selectedProvider={props?.selectedProvider} />
          <ResponsiveContainer>
            <PublicProviderTripHistory />
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
}

export default PublicProviderDetail;

import { collectionQueryBuilder } from "shared/model/collection-query-builder";
import { dashboardApiService } from "store/dashboardApi";

import { ProviderTrackingEndpoint } from "./provider-tracking-endpoint";

const ProviderTrackingApiService = dashboardApiService.injectEndpoints({
  endpoints(builder) {
    return {
      createOperatorRequest: builder.mutation({
        query: (request: any) => ({
          url: `${ProviderTrackingEndpoint.create}`,
          method: "post",
          data: request,
        }),
      }),
      listVehicleCategoryForTracking: builder.query({
        query: (query: any) => ({
          url: `${ProviderTrackingEndpoint.listCategory}`,
          method: "get",
          params: collectionQueryBuilder(query),
        }),
      }),

      listConfigurationForTracking: builder.query({
        query: () => ({
          url: `${ProviderTrackingEndpoint.list}`,
          method: "get",
          headers: "",
        }),
      }),

      createRequest: builder.mutation({
        query: () => ({
          url: `${ProviderTrackingEndpoint.list}`,
          method: "post",
        }),
      }),

      getPassengerTrip: builder.query({
        query: (phone_number) => ({
          url: `${ProviderTrackingEndpoint.getPassengerTrip}?phone_number=${phone_number}`,
          method: "get",
          headers: "",
        }),
      }),

      getNearestDrivers: builder.query({
        query: (query) => ({
          url: `${ProviderTrackingEndpoint.getAvailableDrivers}?filter[0][0][field]=_id&filter[0][0][operator]=in&filter[0][0][value]=${query}&select[0]=phone_number&select[1]=first_name&select[2]=last_name&includes[0]=vehicle`,
          method: "get",
          headers: "",
        }),
      }),
      getDriverDetail: builder.query({
        query: (query) => ({
          url: `${ProviderTrackingEndpoint.getAvailableDrivers}?filter[0][0][field]=_id&filter[0][0][operator]=in&filter[0][0][value]=${query}&select[0]=phone_number&select[1]=first_name&select[2]=last_name&includes[0]=vehicle`,
          method: "get",
          headers: "",
        }),
      }),
    };
  },
});

export const {
  useCreateOperatorRequestMutation,
  useLazyListVehicleCategoryForTrackingQuery,
  useListConfigurationForTrackingQuery,
  useLazyGetPassengerTripQuery,
  useLazyGetNearestDriversQuery,
  useLazyGetDriverDetailQuery,
} = ProviderTrackingApiService;

import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link, useParams } from "react-router-dom";
import SpinnerForPages from "shared/component/spinner-for-pages";

import {
  useCancelRequestMutation,
  useCompleteRequestMutation,
  useStartRequestMutation,
} from "../api/accepted_requests-api-service";
import styles from "./style.module.css";

interface PublicVehicleDetailProps {
  selectedRequest: any;
  selectedRequestLoading: boolean;
}
function RequestDetail(props: PublicVehicleDetailProps) {
  const [selectedRequest, setSelectedVehicle] = useState(props.selectedRequest);

  const [skip, setSkip] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [completeRequest, { isLoading: completing }] =
    useCompleteRequestMutation();
  const [startRequest, { isLoading: starting }] = useStartRequestMutation();
  const [cancelRequest, { isLoading: canceling }] = useCancelRequestMutation();
  const dateFormater = (date) => {
    if (date) {
      const data = new Date(date);
      // Results below assume UTC timezone - your results may vary
      const result = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZoneName: "short",
      }).format(data);
      // Specify default date formatting for language (locale)
      // console.log(new Intl.DateTimeFormat('en-US').format(data))
      return result;
    }
  };

  useEffect(() => {
    setSelectedVehicle(props.selectedRequest);
    if (props.selectedRequest) {
      setSkip(false);
    }
  }, [props.selectedRequest]);

  const onCollapsed = () => {
    var isExpand = !isCollapsed;
    setIsCollapsed(isExpand);
  };
  const onCompleted = () => {
    completeRequest(props.selectedRequest.id);
  };

  const onStart = () => {
    startRequest(props.selectedRequest.id);
  };

  const onCancel = () => {
    cancelRequest(props.selectedRequest.id);
  };
  return (
    <>
      {props.selectedRequestLoading ? (
        <SpinnerForPages />
      ) : (
        <Card className=" h-100">
          <Card.Header className="d-flex justify-content-between">
            <div> Request Detail</div>
            <div className="d-flex gap-2">
              {!isCollapsed && props.selectedRequest.status === "Started" && (
                <Button variant="success" size="sm" onClick={onCompleted}>
                  Complete
                </Button>
              )}
              {!isCollapsed && props.selectedRequest.status === "Started" && (
                <Link
                  to={`/requests/detail/track-driver/${props.selectedRequest.id}?currentLat=${props.selectedRequest.current_location.lat}&currentLng=${props.selectedRequest.current_location.lng}&pickupLat=${props.selectedRequest.exact_starting_point.lat}&pickupLng=${props.selectedRequest.exact_starting_point.lng}&dropLat=${props.selectedRequest.drop_off_location[0]}&dropLng=${props.selectedRequest.drop_off_location[1]}`}
                >
                  <Button variant="warning" size="sm">
                    Track Driver
                  </Button>
                </Link>
              )}

              {!isCollapsed && props.selectedRequest.status === "Accepted" && (
                <Button variant="success" size="sm" onClick={onStart}>
                  Start
                </Button>
              )}
              {!isCollapsed && props.selectedRequest.status === "Accepted" && (
                <Button variant="warning" size="sm" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button size="sm" onClick={onCollapsed}>
                {isCollapsed ? "Expand" : "Collapse"}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className={isCollapsed ? "d-none" : ""}>
            <div className="d-flex ">
              <div style={{ width: "65%" }} className="border mt-2 mx-2 pt-2">
                {/* Drop off */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Drop Off Address
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.drop_off_address}
                  </div>
                </div>

                {/* Pickup */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Pickup Address
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.pickup_address}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Vehicle Type
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.vehicle_type}
                  </div>
                </div>

                {/* Vehicle category */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Vehicle Category
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.vehicle_category}
                  </div>
                </div>

                {/* status */}
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}> Status</div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.status}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Starting Time
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {dateFormater(selectedRequest?.starting_time)}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Completed Time
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {dateFormater(selectedRequest?.completed_time)}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Estimated Distance
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.distance + " KM"}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Total Price
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.price}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>Net Price</div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.net_price}
                  </div>
                </div>

                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Commission
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {selectedRequest?.commission}
                  </div>
                </div>
                <div className="p-2 pt-0 pb-0 d-flex">
                  <div className={`p-2 w-25 ${styles.listKey} `}>
                    Travelled Distance
                  </div>
                  <div className={`p-2 w-75 ${styles.list} `}>
                    {`${(selectedRequest?.exact_distance / 1000).toFixed(
                      2
                    )} KM`}
                  </div>
                </div>
              </div>

              <div style={{ width: "35%" }}>
                <div className="border mt-2 mx-2 pt-2">
                  <h5 className="mx-2">Provider</h5>
                  {/* Name */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Name: {selectedRequest?.driver?.first_name}{" "}
                      {selectedRequest?.driver?.last_name}
                    </div>
                  </div>
                  {/* Phone */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Phone: {selectedRequest?.driver?.phone_number}
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Gender: {selectedRequest?.driver?.gender}
                    </div>
                  </div>
                  {/* Email */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Email: {selectedRequest?.driver?.email}
                    </div>
                  </div>
                </div>
                <div className="border mt-2 mx-2 pt-2">
                  <h5 className="mx-2">Customer</h5>
                  {/* Name */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Name: {selectedRequest?.passenger?.name}
                    </div>
                  </div>
                  {/* Phone */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Phone: {selectedRequest?.passenger?.phone_number}
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Gender: {selectedRequest?.passenger?.gender}
                    </div>
                  </div>
                  {/* Emergency contact */}
                  <div className="p-2 pt-0 pb-0 d-flex">
                    <div className={`p-2 w-100 ${styles.listKey} `}>
                      Emergency Contact:{" "}
                      {selectedRequest?.passenger?.emergency_contact}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default RequestDetail;

export const requestEndpoint = {
    list: `ride-requests/get-ride-requests`,
    detail: `ride-requests/get-ride-request`,
    create: `ride-requests/create-ride-request`,
    update: `ride-requests/update-ride-request`,
    delete: `ride-requests/delete-ride-request`,
    complete: `ride-requests/complete-ride-request`,
    start: `ride-requests/start-trip`,
    cancel: `ride-requests/cancel-ride-request`,
    getSingleRideRequest: `ride-requests/get-ride-request`
};


import { dashboardApiService } from "store/dashboardApi";
import { requestEndpoint } from "./request-endpoint";

const rideRequestApiService = dashboardApiService.injectEndpoints({
  endpoints(builder) {
    return {
      getRideRequestById: builder.query({
        query: (id: string) => ({
          url: `${requestEndpoint.getSingleRideRequest}/${id}`,
          method: "get",
        }),
        
      }),
    };
  },
});

export const { useLazyGetRideRequestByIdQuery } = rideRequestApiService;

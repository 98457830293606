export const publicProviderEndpoint = {
  list: `drivers/get-drivers`,
  detail: `drivers/get-driver`,
  total_drivers_credit: `credits/get-total-credit`,
  getMyCredit: `credits/get-driver-credit`,
  addCredit: `credits/update-credit`,
  listCreditHistory: `credits/get-credit-transactions`,
  listTripHistory: `ride-requests/get-driver-trip-histories`,
  refundDriver: `ride-requests/refund-driver`,
  sendTemporaryPasswordForDrivers: `drivers/send-temporary-password`,
};

import { Credit } from "features/model/credit";
import MessagingServices from "shared/messaging-services";
import { collectionQueryBuilder } from "shared/model/collection-query-builder";
import { dashboardApiService } from "store/dashboardApi";

import { render } from "@testing-library/react";

import { publicProviderEndpoint } from "./public-provider-endpoint";

const PublicProviderApiService = dashboardApiService.injectEndpoints({
  endpoints(builder) {
    return {
      listPublicProvider: builder.query({
        query: (query?: any) => ({
          url: `${publicProviderEndpoint.list}`,
          method: "get",
          params: collectionQueryBuilder(query),
        }),
      }),
      getPublicProviderById: builder.query({
        query: (id: string) => ({
          url: `${publicProviderEndpoint.detail}/${id}`,
          method: "get",
        }),
      }),
      getCredit: builder.query({
        query: (id: string) => ({
          url: `${publicProviderEndpoint.getMyCredit}?driver_id=${id}`,
          method: "get",
        }),
      }),
      getAllDriversTotalCredit: builder.query({
        query: () => ({
          url: `${publicProviderEndpoint.total_drivers_credit}`,
          method: "get",
        }),
      }),
      addCreditFromOperator: builder.mutation({
        query: (credit: any) => ({
          url: `${publicProviderEndpoint.addCredit}`,
          method: "post",
          data: credit,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            render(
              <MessagingServices
                message="Credit added Successfully"
                show={true}
                type={"success"}
              />
            );
          } catch (error) {
            render(
              <MessagingServices
                message="Something went wrong unable to add credit"
                show={true}
                type={"error"}
              />
            );
          }
        },
        invalidatesTags: ["CreditHistory"],
      }),
      sendTemporaryPasswordForDrivers: builder.mutation({
        query: (id: any) => ({
          url: `${publicProviderEndpoint.sendTemporaryPasswordForDrivers}`,
          method: "post",
          data: id,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            render(
              <MessagingServices
                message="Temporary password sent successfully"
                show={true}
                type={"success"}
              />
            );
          } catch (error) {
            render(
              <MessagingServices
                message="Something went wrong unable to send temporary password"
                show={true}
                type={"error"}
              />
            );
          }
        },
        // invalidatesTags: ["CreditHistory"],
      }),
      listCreditHistory: builder.query({
        query: (query: any) => ({
          url: `${publicProviderEndpoint.listCreditHistory}`,
          method: "get",
          params: collectionQueryBuilder(query),
        }),
        providesTags: ["CreditHistory"],
      }),

      refundDriver: builder.mutation({
        query: (data: any) => ({
          url: `${publicProviderEndpoint.refundDriver}`,
          method: "post",
          data: data,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            render(
              <MessagingServices
                message="Refunded Successfully"
                show={true}
                type={"success"}
              />
            );
          } catch (error) {
            render(
              <MessagingServices
                message="not refunded Something went wrong"
                show={true}
                type={"error"}
              />
            );
          }
        },
      }),

      listTripHistory: builder.query({
        query: ({ request, id }) => ({
          url: `${publicProviderEndpoint.listTripHistory}/${id}`,
          method: "get",
          params: collectionQueryBuilder(request),
        }),
      }),
    };
  },
});

export const {
  useLazyListPublicProviderQuery,
  useGetPublicProviderByIdQuery,
  useLazyGetAllDriversTotalCreditQuery,
  useAddCreditFromOperatorMutation,
  useSendTemporaryPasswordForDriversMutation,
  useLazyListCreditHistoryQuery,
  useGetCreditQuery,
  useLazyListTripHistoryQuery,
  useRefundDriverMutation,
} = PublicProviderApiService;

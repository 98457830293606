import React, { useEffect, useState, useMemo } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";
import { baseUrl, googleMapApiKey } from "config/constants";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyGetRideRequestByIdQuery } from "../api/request-service";
import SpinnerForPages from "shared/component/spinner-for-pages";
import axios from "axios";
import { requestEndpoint } from "../api/request-endpoint";

const TrackDriver = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const [currentLat, setCurrentLat] = useState<number>();
  const [currentLng, setCurrentLng] = useState<number>();

  // const [data, setData] = useState<any>();

  const { id } = useParams();

  const [getRideRequest, getRideResponse] = useLazyGetRideRequestByIdQuery();

  const [directions, setDirections] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const center = {
    lat: +(searchParams.get("currentLat") ?? 0.0),
    lng: +(searchParams.get("currentLng") ?? 0.0),
  };

  let pathCoordinates = [
    {
      lat: +(searchParams.get("pickupLat") ?? 0.0),
      lng: +(searchParams.get("pickupLng") ?? 0.0),
    },
    {
      lat: +(searchParams.get("currentLat") ?? 0.0),
      lng: +(searchParams.get("currentLng") ?? 0.0),
    },
    {
      lat: +(searchParams.get("dropLat") ?? 0.0),
      lng: +(searchParams.get("dropLng") ?? 0.0),
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchRideRequest = async () => {
      if (id) {
        const intervalId = setInterval(async () => {
          const { data } = await axios.get(
            `${baseUrl}${requestEndpoint.getSingleRideRequest}/${id}`,
            {
              headers: {
                "x-access-token": token ?? "", // Attach the token here
              },
            }
          );

          if (data) {
            fetchDirections(data);
            setCurrentLat(data.current_location.lat);
            setCurrentLng(data.current_location.lng);
          }
        }, 10000); // 10 seconds interval

        // Cleanup interval on component unmount or when id changes
        return () => clearInterval(intervalId);
      }
    };

    fetchRideRequest();
  }, []);

 

  const fetchDirections = (data) => {
    console.log("fetching");
    if (!window.google) {
      console.error("Google maps API is not loaded yet");
      return;
    }

    console.log(data);
    if (data) {
      pathCoordinates = [
        {
          lat: data.exact_starting_point.lat,
          lng: data.exact_starting_point.lng,
        },
        {
          lat: data.current_location.lat,
          lng: data.current_location.lng,
        },
        {
          lat: data.drop_off_location[0],
          lng: data.drop_off_location[1],
        },
      ];
    }

    const waypoints = [
      {
        location: new window.google.maps.LatLng(
          +(searchParams.get("currentLat") ?? 0.0),
          +(searchParams.get("currentLng") ?? 0.0)
        ),
      }, // Example waypoint (Nashville, TN)
      // { location: new window.google.maps.LatLng(35.2271, -80.8431) }  // Example waypoint (Charlotte, NC)
    ];

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: pathCoordinates[0],
        destination: pathCoordinates[pathCoordinates.length - 1],
        // waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  return (
    <>
      {getRideResponse.isLoading ? (
        <SpinnerForPages />
      ) : (
        <LoadScript googleMapsApiKey={googleMapApiKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
          >
            {/* <Polyline path={pathCoordinates} options={polylineOptions} /> */}
            {directions && <DirectionsRenderer directions={directions} />}
            {/* <Marker position={pathCoordinates[0]} label="Start" /> */}
            <Marker
              position={{ lat: +(currentLat ?? 0), lng: +(currentLng ?? 0) }}
              label="D"
            />
            {/* <Marker
          position={pathCoordinates[pathCoordinates.length - 1]}
          label="End"
        /> */}
          </GoogleMap>
        </LoadScript>
      )}
    </>
  );
};

export default TrackDriver;

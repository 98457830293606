import * as yup from "yup";

export const schema = yup
  .object()
  .shape({
    amount: yup
      .number()
      .required("Is required")
      .positive()
      .integer()
      .min(5, "Min is 5"),
  })
  .required();
